import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import ArticleList from "../components/articleList"
import SEO from "../components/seo"
import Profile from "../components/profile"
import { Row } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import "prismjs/themes/prism-okaidia.css"
import "../styles/global.css"

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const profileImage = data.site.siteMetadata.profile
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout>
        <SEO
          title="All posts"
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        <Row>
          <ArticleList postEdges={posts} />
        </Row>
        <Profile profileImage={profileImage} />
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        profile
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            image {
              publicURL
            }
            category
          }
        }
      }
    }
  }
`

BlogIndex.propTypes = {
  data: PropTypes.object,
  profile: PropTypes.string,
}
