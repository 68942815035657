import React from "react"
import { Col, Card } from "react-bootstrap"
import { Link } from "gatsby"
import "bootstrap/dist/css/bootstrap.min.css"
import styles from "../styles/article_list.module.css"
import PropTypes from "prop-types"

class ArticleList extends React.Component {
  render() {
    const { postEdges } = this.props

    return postEdges.map(({ node }) => {
      return (
        <Col
          lg={{ span: 8, offset: 2 }}
          xl={{ span: 6, offset: 3 }}
          key={node.id}
        >
          <Link className={styles.list} to={node.fields.slug}>
            <Card className={styles.item}>
              <Card.Img
                variant="top"
                className={styles.image}
                src={node.frontmatter.image.publicURL}
              />
              <Card.Body>
                <Card.Title className={styles.title}>
                  {node.frontmatter.title}
                </Card.Title>
                <Card.Text>
                  <time className={styles.time}>{node.frontmatter.date}</time>
                  <div>
                    <span className={styles.tag}>
                      {node.frontmatter.category}
                    </span>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </Link>
        </Col>
      )
    })
  }
}

export default ArticleList

ArticleList.propTypes = {
  postEdges: PropTypes.object,
}
